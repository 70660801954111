const WordsList = [
    [
        {id:'1w1', word:'january', description: 'The word comes from "Janus", which means God of Doors'},
        {id:'1w2', word:'february', description: 'The word comes from "februo", which means Purify'},
        {id:'1w3', word:'march', description: 'The word comes from "Mars", which means God of War'},
        {id:'1w4', word:'april', description: 'The word comes from "aperire", which means Open'},
        {id:'1w5', word:'may', description:'The word comes from "Maia", which means Goddess of Growth'},
        {id:'1w6', word:'june', description: 'The word comes from "Juno", which means Queen of the Gods'},
        {id:'1w7', word:'july', description: 'The month is named after"Julius Caesar", Ruler of Rome'},
        {id:'1w8', word:'august', description: 'The month is named after "Augustus", Ruler of Rome'},
        {id:'1w9', word:'september', description: 'The word comes from "septem", which means Seven'},
        {id:'1w10', word:'october', description: 'The word comes from "octo", which means Eight'},
        {id:'1w11', word:'november', description: 'The word comes from "novem", which means Nine'},
        {id:'1w12', word:'december', description: 'The word comes from "decem", which means Ten'},
    ],
    [
        {id:'2w1', word:'mercury', description: 'The smallest planet in our solar system'},
        {id:'2w2', word:'venus', description: 'Hottest planet in our solar system'},
        {id:'2w3', word:'earth', description: 'Only planet in our system with liquid water on the surface'},
        {id:'2w4', word:'mars', description: 'A dusty cold desert world with a very thin atmosphere'},
        {id:'2w5', word:'jupiter', description:'More than twice as massive than the other planets combined'},
        {id:'2w6', word:'saturn', description: 'A planet with a dazzling, complex system of icy rings'},
        {id:'2w7', word:'uranus', description: 'The planet appears to spin on its side.'},
        {id:'2w8', word:'neptune', description: 'The first planet located through mathematical calulations'},
    ],
    [
        {id:'3w1', word:'silver', description: 'The shiniest color'},
        {id:'3w2', word:'maroon', description: 'Dark Red with a cool name'},
        {id:'3w3', word:'purple', description: 'Pink for men'},
        {id:'3w4', word:'lime', description: 'If green was painful to look at'},
        {id:'3w5', word:'aqua', description:'The cooler brother of cyan'},
        {id:'3w6', word:'orange', description: 'Someone copied the name of a fruit'},
        {id:'3w7', word:'magenta', description: 'Pink after it went to Oxford'},
        {id:'3w8', word:'violet', description: 'If pink and purple had a baby'},
    ],
    [
        {id:'4w1', word:'frozen', description: 'The 2013 disney movie directed by Chris Buck, Jennifer Lee'},
        {id:'4w2', word:'cars', description: 'The 2006 pixar movie directed by John Lasseter, Joe Ranft'},
        {id:'4w3', word:'ratatouille', description: 'The 2007 pixar movie directed by Brad Bird'},
        {id:'4w4', word:'aladdin', description: 'The 1992 disney movie.'},
        {id:'4w5', word:'madagascar', description:'The 2005 movie starring Chris Rock'},
        {id:'4w6', word:'goodfellas', description: 'The 1990 movie directed by Martin Scorsese'},
        {id:'4w7', word:'up', description: 'The 2009 pixar movie'},
        {id:'4w8', word:'jaws', description: 'The 1975 blockbuster by Steven Spielberg'},
    ],
    [
        {id:'5w1', word:'achluophobia', description: 'The fear of darkness.'},
        {id:'5w2', word:'claustrophobia', description: 'The fear of confined spaces'},
        {id:'5w3', word:'arachnophobia', description: 'The fear of spiders'},
        {id:'5w4', word:'glossophobia', description: 'The fear of speaking in public'},
        {id:'5w5', word:'hemophobia', description:'The fear of blood'},
        {id:'5w6', word:'acrophobia', description: 'The fear of heights'},
        {id:'5w7', word:'mysophobia', description: 'The fear of dirt and germs'},
        {id:'5w8', word:'nyctophobia', description: 'The fear of the dark'},
    ],
    [
        {id:'6w1', word:'allosaurus', description: 'It reigned as one of the Late Jurassic\'s top predators'},
        {id:'6w2', word:'stegosaurus', description: 'Known for its dazzling assortment of dense, bony plates, and spikes arranged along its backbone.'},
        {id:'6w3', word:'ankylosaurus', description: 'It was one of the armored reptilian tanks of the Cretaceous.'},
        {id:'6w4', word:'velociraptor', description: 'Sickle-clawed dinosaur that flourished in central and eastern Asia during the Late Cretaceous Period'},
        {id:'6w5', word:'triceratops', description:'The Triceratops skull is one of the largest and most striking of any land animal.'},
        {id:'6w6', word:'brachiosaurus', description: 'A sauropod that had very long front legs and a long, flexible neck.'},
        {id:'6w7', word:'spinosaurus', description: 'It was longer and heavier than Tyrannosaurus and is the largest known carnivorous dinosaur.'},
        {id:'6w8', word:'mosasaurus', description: 'A ferocious predator in the ancient oceans of the Cretaceous period'},
    ],
];


export default WordsList;